import React, { useContext, useEffect } from "react";

import styled from "styled-components";
import Box from "./motion/motion-box";
import WorkItem from "./work-item";
import { AnimateSharedLayout } from "framer-motion";
import { FilterContext } from "../gatsby-plugin-layouts/layout";
import { shuffleArray } from "../functions/util";
import MasonryGridWrapper from "./masonry-grid/src/masonry-grid";

const Checkers = styled.span`
  position: absolute;

  /* LEFT */
  &.checkers-left {
    top: 0;
    right: 0;
    height: 100%;
    width: var(--pad);
    background: var(--v);
    z-index: 204;
  }

  /* RIGHT */
  &.checkers-right {
    top: 0;
    left: 0;
    height: 100%;
    width: var(--pad);
    background: var(--v);
    z-index: 205;
  }

  /* TOP */
  &.checkers-top {
    top: 0;
    left: 0;
    width: 100%;
    height: var(--pad);
    background: var(--h);
    z-index: 206;
  }

  /* BOTTOM */
  &.checkers-bottom {
    bottom: 0;
    left: 0;
    width: calc(100% + var(--pad));
    height: var(--pad);
    background: var(--h);
    z-index: 205;
  }
`;

const EntryGrid = styled.section`
  position: relative;
  padding: 0 var(--pad) 0 0;
  background: var(--light);
  z-index: 5;
  margin-bottom: -8px;
`;

export default function WorkGrid({ data }) {
  const { shuffle } = useContext(FilterContext);

  useEffect(() => {
    shuffleArray(data);
  }, [shuffle, data]);

  return (
    <Box
      hiddenStyles={{
        opacity: 0,
        y: 0,
      }}
      visibleStyles={{
        transition: {
          delay: 6,
        },
      }}
    >
      <FilterContext.Consumer>
        {({ showGroup }) => {
          return (
            <AnimateSharedLayout>
              <EntryGrid className="grid">
                <MasonryGridWrapper
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 1350: 3 }}
                >
                  {data &&
                    data.map(({ node: { tags, uid, data: itemData } }, i) => {
                      if (tags.includes(showGroup) || showGroup === "all")
                        return (
                          <WorkItem
                            key={i}
                            id={i}
                            itemData={itemData}
                            uid={uid}
                          />
                        );
                      else return null;
                    })}
                </MasonryGridWrapper>
                <Checkers className="checkers-top" />
                <Checkers className="checkers-right" />
                <Checkers className="checkers-bottom" />
                <Checkers className="checkers-left" />
              </EntryGrid>
            </AnimateSharedLayout>
          );
        }}
      </FilterContext.Consumer>
    </Box>
  );
}

import React, { useMemo, useEffect, useState } from "react";
import { MasonryGridProps } from "./masonry-grid.types";

const DEFAULT_COLUMNS_COUNT = 1;

const throttle = (callback: () => void, interval: number): (() => void) => {
  let pendingTimeout = false;

  return (...args) => {
    if (pendingTimeout) return;

    pendingTimeout = true;
    callback.apply({}, args);

    setTimeout(() => {
      pendingTimeout = false;
    }, interval);
  };
};

type WindowSize = { height: number; width: number };

const useWindowResizeListener = (throttleDelay = 150): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (typeof window === "undefined") return undefined;

    const updateWindowSize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    updateWindowSize();

    const throttledCallbackFn = throttle(updateWindowSize, throttleDelay);
    window.addEventListener("resize", throttledCallbackFn);

    return () => {
      window.removeEventListener("resize", throttledCallbackFn);
    };
  }, [throttleDelay]);

  return windowSize;
};

export { useWindowResizeListener };

const MasonryGridWrapper = ({
  columnsCountBreakPoints = {
    "300": 1,
    "1024": 2,
  },
  gap = "0",
  className = "",
  style = {},
  children,
}: MasonryGridProps) => {
  const { width } = useWindowResizeListener();
  const columnsCount = useMemo(() => {
    const breakPoints = Object.keys(columnsCountBreakPoints).sort(
      (a: any, b: any) => a - b
    ) as string[] | number[];

    let count =
      breakPoints.length > 0
        ? columnsCountBreakPoints[breakPoints[0]]
        : DEFAULT_COLUMNS_COUNT;

    breakPoints.forEach((breakPoint) => {
      if (breakPoint < width) {
        count = columnsCountBreakPoints[breakPoint];
      }
    });

    return count;
  }, [width, columnsCountBreakPoints]);

  function getColumns() {
    const columns = Array.from({ length: columnsCount }, () => []);

    React.Children.forEach(children, (child, index): any => {
      if (child && React.isValidElement(child)) {
        columns[index % columnsCount].push(child);
      }
    });

    return columns;
  }

  function renderColumns() {
    return getColumns().map((column, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "stretch",
          flex: 1,
          width: 0,
          gap: gap,
        }}
      >
        {column.map((item) => item)}
      </div>
    ));
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "stretch",
        boxSizing: "border-box",
        width: "100%",
        gap: gap,
        ...style,
      }}
      className={className}
    >
      {renderColumns()}
    </div>
  );
};

export default MasonryGridWrapper;

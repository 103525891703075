import React from "react";
import { graphql } from "gatsby";

import WorkGrid from "../components/work-grid";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export function HomeTemplate({ data: { allPrismicWorkEnt } }) {
  const workEntryData = allPrismicWorkEnt.edges;

  return <WorkGrid data={workEntryData} />;
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query HomePageTemplate {
    allPrismicWorkEnt(
      sort: { fields: data___date, order: DESC }
      filter: { data: { hide_from_lists: { nin: true } } }
    ) {
      edges {
        node {
          id
          uid
          tags
          data {
            date
            color
            client
            hide_from_lists
            description {
              text
              raw
              html
            }
            title {
              text
            }
            body {
              ... on PrismicWorkEntDataBodyVideo {
                id
                slice_type
                primary {
                  video {
                    url
                  }
                }
              }
              ... on PrismicWorkEntDataBodyImage {
                id
                slice_type
                primary {
                  image {
                    fluid(maxWidth: 1800) {
                      ...GatsbyPrismicImageFluid
                      aspectRatio
                    }
                  }
                }
              }
              ... on PrismicWorkEntDataBodyButton {
                id
                slice_type
                primary {
                  external_link_text
                  link {
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomeTemplate;
